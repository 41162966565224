
form[name=shop-switch-form] {
  display: flex;
}
form[name=shop-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=shop-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=shop-switch-form] > div > div {
  flex-grow:1;
}
form[name=shop-switch-form] > button {
  margin-left:10px;
}


.col.buttons[data-v-12039d52] {
  text-align: right;
}
a.toggleDetails i[data-v-12039d52]:last-child {
      margin-left:5px;
      transition: 0.3s ease all;
}
a.toggleDetails.opened i[data-v-12039d52]:last-child {
      transform: rotate(180deg);
}
.orders[data-v-12039d52] {
    display:flex;
}
.card.order[data-v-12039d52] {
    padding:5px 10px;
}

